import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import HarvestProductSection from '../harvest-product-section/HarvestProductSection';
import { HarvestProductMain } from 'components';
import { useBundleSeedlings } from 'utils/hooks';
import { getCustomPlantBundlesByRefSku, getFarmstands, getGlowRings } from 'reduxState/catalog';
import { addBulkItems, openCartModal } from 'reduxState/cart';
import { getFarmSizeFromName } from 'utils/string-utils';
import { formatPrice } from 'utils/cart-utils';
import { glowRingsMapSizeSku } from 'constants/sku';
import paths from 'constants/paths';
import { getFarmstandCatalogBySize, getCustomBundleSeedlingsNames } from 'utils/productBundlesUtils';
import { TOP_PICK_FARM_SIZE } from '../../constants/productBundlesConstants';

const mapSectionsBySlug = {
  [paths.HARVEST_PLAN.ZOOEY]: {
    ENV_ZIP_ID: '4EHQ0FHLMCw2efEfenDXe9',
    FARM_PLAN_ID: '2MNaFY5Vte87kx9fAFltnd',
    SEED_PLAN_ID: '1HBNFvZEceyTbtkeZQRSKO',
  },
  [paths.HARVEST_PLAN.CHIPOTLE]: {
    ENV_ZIP_ID: '6scwNkQQMkXnHbERSI86X',
    FARM_PLAN_ID: 'mSKam0Ltxvci68C0Ftjzn',
    SEED_PLAN_ID: '3obQowYAaSh5spyHOlzHXB',
  },
  [paths.HARVEST_PLAN.MATTJAMES]: {
    ENV_ZIP_ID: '6A53Dmhd4Aprg84UNIrLCa',
    FARM_PLAN_ID: '21zaGp6SEV6YNmlLUUyuk3',
    SEED_PLAN_ID: '3Am1XivYerpdk08cyofoFq',
  },
  [paths.HARVEST_PLAN.BRIGHTLAND]: {
    ENV_ZIP_ID: '2RQcJ7ZJ0wyHxHdyuV60vm',
    FARM_PLAN_ID: '5JAKTTshLHTuMxcfEiXtab',
    SEED_PLAN_ID: 'ytd3tcNMRjUum1In4GZgs',
  },
  [paths.HARVEST_PLAN.THRIVE]: {
    ENV_ZIP_ID: '63CNmxIQumA6EyZgUF0oTm',
    FARM_PLAN_ID: '79z0mqTzV1BoRvO28OQSBv',
    SEED_PLAN_ID: '3BygwHhuVv0q0se8Noau6R',
  },
};

const PICK_LABEL_ID = '6pSWUzTjH6qLM2JZyyg0we';
const GLOW_RINGS_ID = 'LT1hY0KfNUwNLnbLegYDR';

const TOGGLE_ITEMS_LABELS = ['FARMSTAND + SEEDLINGS', 'SEEDLINGS'];

const HarvestProductFarmBundleSection = ({ title, body, sections = [], slug }) => {
  const slugSectionsIds = mapSectionsBySlug[slug];
  const envZipData = sections?.find?.((e) => e?.sys?.id === slugSectionsIds?.ENV_ZIP_ID)?.fields;
  const farmPlanData = sections?.find?.((e) => e?.sys?.id === slugSectionsIds?.FARM_PLAN_ID)?.fields;
  const seedPlanData = sections?.find?.((e) => e?.sys?.id === slugSectionsIds?.SEED_PLAN_ID)?.fields;
  const plantBundle = seedPlanData?.percentages?.[0];

  const dispatch = useDispatch();
  const [buttonPrice, setButtonPrice] = useState('');
  const [selectedSize, setSelectedSize] = useState(TOP_PICK_FARM_SIZE);
  const [selectedOption, setSelectedOption] = useState(0);
  const [isGRChecked, setIsGRChecked] = useState(false);
  const [seedlingsList, setSeedlingsList] = useState([]);
  const farmstandsCatalog = useSelector(getFarmstands);
  const glowRingsCatalog = useSelector(getGlowRings);
  const seedlingsCatalog = useSelector(({ catalog }) => catalog.seedlings);
  const bundlesCatalog = useSelector((state) => getCustomPlantBundlesByRefSku(state, plantBundle?.sys?.id));
  const isFirstSelected = selectedOption === 0;

  const selectedFarmOrDefault = getFarmstandCatalogBySize(farmstandsCatalog, selectedSize);
  const selectedGlowRing = glowRingsCatalog?.find((gr) => gr?.sku === glowRingsMapSizeSku[getFarmSizeFromName(selectedFarmOrDefault)]);
  const selectedBundle = bundlesCatalog?.find((bundle) => bundle?.plantCount?.toString() === getFarmSizeFromName(selectedFarmOrDefault));
  const { seedlings } = useBundleSeedlings(selectedBundle?.sku);
  const bundleSeedlingsSkus = seedlings;
  const bundleSeedlingsNames = getCustomBundleSeedlingsNames(seedlingsList, seedlingsCatalog);
  const availableSizes = farmstandsCatalog?.map?.((farm) => parseInt(getFarmSizeFromName(farm), 10));

  const sectionData = isFirstSelected ? farmPlanData : seedPlanData;

  const onSelectSize = (newSize) => setSelectedSize(newSize);

  const onToggleOption = (newOption) => setSelectedOption(newOption);

  const onGlowRingsCheck = (isChecked) => setIsGRChecked(isChecked);

  const updateButtonPrice = () => {
    const selectedFarmPrice = (isFirstSelected && selectedFarmOrDefault?.priceCents) || 0;
    const selectedGRPrice = (isFirstSelected && isGRChecked && selectedGlowRing?.priceCents) || 0;
    const totalPriceCents = selectedBundle?.priceCents + selectedFarmPrice + selectedGRPrice;
    setButtonPrice(formatPrice(totalPriceCents / 100, 0));
  };

  const onAddToCart = () => {
    if (selectedBundle) {
      const items = [{ ...selectedBundle, qty: 1 }];
      if (isFirstSelected) items.push({ ...selectedFarmOrDefault, qty: 1 });
      if (isFirstSelected && isGRChecked) items.push({ ...selectedGlowRing, qty: 1 });
      dispatch(addBulkItems({ items }));
      dispatch(openCartModal());
    }
  };

  useEffect(() => {
    if (bundleSeedlingsSkus.length) setSeedlingsList(bundleSeedlingsSkus);
  }, [bundleSeedlingsSkus]);

  useEffect(() => {
    if (selectedSize === selectedBundle?.plantCount) updateButtonPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, selectedSize, selectedBundle, isGRChecked]);

  const pickLabelData = sectionData?.percentages?.find?.((e) => e?.sys?.id === PICK_LABEL_ID)?.fields;
  const glowRingsData = sectionData?.percentages?.find?.((e) => e?.sys?.id === GLOW_RINGS_ID)?.fields;

  if (!farmstandsCatalog?.length || !bundlesCatalog?.length || !Object.values(seedlingsCatalog)?.length) return null;

  return (
    <HarvestProductSection title={title} body={body} envZipData={envZipData}>
      <HarvestProductMain
        topPickSize={TOP_PICK_FARM_SIZE}
        bundlesSeedlings={bundleSeedlingsNames}
        toggleFirstLabel={farmPlanData?.lowerTitle || TOGGLE_ITEMS_LABELS[0]}
        toggleSecondLabel={seedPlanData?.lowerTitle || TOGGLE_ITEMS_LABELS[1]}
        buttonPrice={buttonPrice}
        isGRChecked={isGRChecked}
        selectedSize={selectedSize}
        selectedOption={selectedOption}
        selectedGlowRing={selectedGlowRing}
        pickLabelData={pickLabelData}
        glowRingsData={glowRingsData}
        sectionData={sectionData}
        availableSizes={availableSizes}
        onSelectSize={onSelectSize}
        onToggleOption={onToggleOption}
        onGlowRingsCheck={onGlowRingsCheck}
        onAddItemsToCart={onAddToCart}
      />
    </HarvestProductSection>
  );
};

HarvestProductFarmBundleSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  sections: PropTypes.array,
  slug: PropTypes.string,
};

export default HarvestProductFarmBundleSection;
